import { useEffect, useState } from "preact/hooks";
import { CardActionsButtons } from "./components/CardActionsButtons";
import { BcCard } from "../globalComponents/card/BcCard";
import { usePortcallCardState } from "../../hooks/usePortcallCardState";
import { areEqual } from "../../utils/helpers/globalHelpers";
import { formatPayload, validateForm } from "./utils/helpers";
import { useHttp } from "../../hooks/useHttp";
import { API_METHODS } from "../../utils/constants/api";
import { Loader } from "../loader/Loader";
import { LARGE_STYLE } from "../../utils/constants/styleConstants";
import {
  bcDateTimeFormat,
  WITH_TIME_FORMAT,
} from "../../utils/helpers/dateTimePickerHelpers";
import { updateScheduledPortcall } from "../../db";
import ArrivalSectionMui from "./components/mui/ArrivalSectionMui";
import DepartureSectionMui from "./components/mui/DepartureSectionMui";
import { LoadingDischargingSectionMui } from "./components/mui/LoadingDischargingSectionMui";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import BunkersLiftedMui from "./components/mui/BunkersLiftedMui";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import RemarksSectionMui from "./components/mui/RemarksSectionMui";
import { useBunkersSubmitFunctionality } from "./components/BunkersLifted/hooks/useBunkersSubmitFunctionality";
import Alert from "../../mui/components/alert/Alert";
import { BunkerProductType } from "../../utils/enums";
import { DirectionsBoatOutlined } from "@mui/icons-material";

const PortcallCard = ({
  archivedPortcall,
  portcall,
  setPortcalls,
  portcallsList,
  previousPortcall,
  highlightedPortcallsFields,
  setHighlightedPortcallsFields,
  isAnyPortcallInEditMode,
  setIsAnyPortcallInEditMode,
  baseUrlToMainApp,
  portcallIndex,
  className,
  propsRef,
  needToShowInPortBtn,
  ...props
}) => {
  const {
    portCallState,
    setEta,
    setEtaToShow,
    setEtd,
    setStartOfSeaPassage,
    setEndOfSeaPassage,
    setDepartureDraft,
    setArrivalDraft,
    setRemarks,
    setBillOfLadingStatus,
    resetPortCallState,
    setVesselLastUpdated,
    setBunkersFuel,
    setFreshWater,
    setEarlyDelayArrivalReason,
    setEarlyDelayArrivalComment,
    setFieldValue,
    setLiftedBunkerProductField,
    setLiftedBunkerProductBargeField,
    portCallInitial,
    setPortCallInitial,
  } = usePortcallCardState(portcall);

  const {
    portName,
    agentName,
    remarks,
    cargoes = [],
    portCallId,
    agentChangedAt,
    hubCode,
    liftedBunkerProductsInPort,
    bunkersTaken,
    principalOrganizationId,
    commercialBunkerFactorPreferences,
  } = portCallState;

  const [editMode, setEditMode] = useState(false);
  const [earliestEndOfLaycan, setEarliestEndOfLaycan] = useState("");
  const [commercialManagerOrgName, setCommercialManagerOrgName] = useState("");
  const [
    getEarliestEndOfLaycanErrMessage,
    setGetEarliestEndOfLaycanErrMessage,
  ] = useState(null);
  const [
    editPortCallDetailsSuccessMessage,
    setEditPortCallDetailsSuccessMessage,
  ] = useState(null);
  const [editPortCallDetailsErrorMessage, setEditPortCallDetailsErrorMessage] =
    useState(null);
  const [errors, setErrors] = useState({});
  const { request, isLoading, toaster } = useHttp(
    setEditMode,
    setIsAnyPortcallInEditMode
  );
  const {
    bunkerFactorsValues,
    setBunkerFactorValues,
    bunkerFactorSettings,
    initialBunkerFactorsValues,
    updateBunkers,
    isChanged,
    resetBunkerFactors,
  } = useBunkersSubmitFunctionality({
    liftedBunkerProductsInPort,
    request,
    setErrors,
    setLiftedBunkerProductField,
  });
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
  const tabLabels = liftedBunkerProductsInPort.map((product) => ({
    label: BunkerProductType[product.type],
    type: product.type,
  }));

  const isInPortButtonShown =
    (archivedPortcall ? portcallIndex === 0 : needToShowInPortBtn) &&
    props.groupIdx === 0;

  useEffect(() => {
    if (editMode && portCallId) {
      request(
        `GetEarliestEndOfLaycanInPortCall?portCallId=${portCallId}`,
        API_METHODS.GET,
        null,
        (data) => {
          setCommercialManagerOrgName(data?.commercialManagerOrganizationName);
          setEarliestEndOfLaycan(data?.earliestEndOfLaycan);
        },
        (err) => {
          setGetEarliestEndOfLaycanErrMessage(err);
        }
      );
    }
  }, [editMode, portCallId, request]);

  const submitChanges = () => {
    const noChanges = areEqual(portCallInitial, portCallState);
    const { isFillingRequired } = commercialBunkerFactorPreferences;
    const {
      isValid,
      errorsObj,
      highlightedPortcallFieldsObj,
      bunkerFactorsIsValid,
    } = validateForm(
      portCallState,
      previousPortcall,
      bunkerFactorsValues,
      commercialBunkerFactorPreferences
    );
    if (!isValid) {
      setErrors(errorsObj);
      setHighlightedPortcallsFields(highlightedPortcallFieldsObj);
      if (!bunkerFactorsIsValid) {
        const replaceAfterHyphen = (text) => {
          return text.replace(/-.*/, "");
        };
        const error = Object.keys(errorsObj).find(
          (key) => BunkerProductType[replaceAfterHyphen(key)]
        );
        const tabWithError = tabLabels.findIndex((tab) => {
          return tab.label === replaceAfterHyphen(error);
        });
        if (tabWithError !== -1) {
          setSelectedTab(tabWithError);
        }
        return;
      }
      return;
    }
    isFillingRequired && updateBunkers();

    const formattedPayload = formatPayload(
      portCallState,
      bunkerFactorsValues,
      isFillingRequired
    );

    request(
      "EditPortCallDetails",
      API_METHODS.POST,
      noChanges && !isChanged
        ? {
            portCallId: formattedPayload.portCallId,
            noChanges,
          }
        : { ...formattedPayload },

      () => {
        setVesselLastUpdated();
        setEditMode(false);
        setIsAnyPortcallInEditMode(false);
        setPortCallInitial(portCallState);
        setEditPortCallDetailsSuccessMessage("Successfully updated!");
      },
      (err) => {
        setEditPortCallDetailsErrorMessage(`Failed to update. ${err}`);
        resetBunkerFactors();
      },
      async () => {
        await updateScheduledPortcall(portCallState).then(() =>
          setPortCallInitial(portCallState)
        );
      }
    );
  };

  const isSubmitFormBtnDisabled =
    (areEqual({ ...portCallInitial }, { ...portCallState }) && !isChanged) ||
    (portCallState?.earlyDelayArrival?.reasons?.includes(5000) &&
      portCallState.earlyDelayArrival.comment == null);

  const cancelClickHandler = () => {
    resetPortCallState(portCallInitial);
    resetBunkerFactors();
    setEditMode(false);
    setIsAnyPortcallInEditMode(false);
    setErrors({});
    setHighlightedPortcallsFields({});
  };

  const updateClickHandler = () => {
    setEditMode(true);
    setIsAnyPortcallInEditMode(true);
  };

  const agentLastUpdatedFormatted = bcDateTimeFormat(
    agentChangedAt,
    WITH_TIME_FORMAT,
    "Never"
  );
  const vesselLastUpdated = bcDateTimeFormat(
    portCallState.vesselChangedAt,
    WITH_TIME_FORMAT,
    "Never"
  );

  const isHighlightedField = (fieldName) => {
    return highlightedPortcallsFields?.[portCallId]?.includes(fieldName);
  };

  return (
    <>
      {toaster}
      <Alert
        message={getEarliestEndOfLaycanErrMessage}
        severity="error"
        onClose={() => setGetEarliestEndOfLaycanErrMessage(null)}
      />
      <Alert
        message={editPortCallDetailsSuccessMessage}
        severity="success"
        onClose={() => setEditPortCallDetailsSuccessMessage(null)}
      />
      <Alert
        message={editPortCallDetailsErrorMessage}
        severity="error"
        onClose={() => setEditPortCallDetailsErrorMessage(null)}
      />
      <BcCard
        propsRef={propsRef}
        cardClassName={className}
        submitHandler={submitChanges}
        isActive={portcall.isActive}
        delaysCount={portCallState?.earlyDelayArrival?.reasons?.length ?? 0}
        renderHeader={() => {
          return (
            <Stack
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{
                backgroundColor: portcall.isActive
                  ? "components.card.header.active.background"
                  : "components.card.header.archive.background",
                width: "100%",
                padding: "11px 10px 11px 20px",
                border: "1px solid",
                borderColor: portcall.isActive
                  ? "components.card.header.active.border"
                  : "components.card.header.archive.border",
                borderTopLeftRadius: "5px",
                borderTopRightRadius: "5px",
              }}
            >
              <Typography
                variant="h5"
                component="h5"
                sx={{
                  fontWeight: 500,
                  fontSize: "20px",
                  lineHeight: "24.2px",
                  color: "components.card.header.portcallId",
                }}
              >
                {portName} ({hubCode})
              </Typography>
              <Stack
                flexDirection="row"
                justifyContent="flex-end"
                alignItems="center"
              >
                <Typography
                  variant="body1"
                  component="body1"
                  sx={{
                    fontWeight: 600,
                    fontSize: "14px",
                    lineHeight: "16px",
                    color: "components.card.header.portcallId",
                  }}
                >
                  {agentName}
                </Typography>
                <Typography
                  variant="body1"
                  component="body1"
                  sx={{
                    fontWeight: 400,
                    fontSize: "14px",
                    lineHeight: "16px",
                    color: "components.card.header.date",
                    margin: "0 20px 0 27px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <PersonOutlineOutlinedIcon
                    sx={{ color: "components.card.header.icon" }}
                  />{" "}
                  {agentLastUpdatedFormatted}
                </Typography>
                <Typography
                  variant="body1"
                  component="body1"
                  sx={{
                    fontWeight: 400,
                    fontSize: "14px",
                    lineHeight: "16px",
                    color: "components.card.header.date",
                    margin: "0 20px 0 0",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <DirectionsBoatOutlined
                    sx={{
                      color: "components.card.header.icon",
                      fontSize: "23px",
                      paddingRight: "3px",
                    }}
                  />{" "}
                  {vesselLastUpdated}
                </Typography>
                <CardActionsButtons
                  editMode={editMode}
                  setEditMode={setEditMode}
                  isLoading={isLoading}
                  isSubmitFormBtnDisabled={isSubmitFormBtnDisabled}
                  isNoChangesBtnDisabled={!isSubmitFormBtnDisabled}
                  isInPortButtonShown={isInPortButtonShown}
                  cancelClickHandler={cancelClickHandler}
                  updateClickHandler={updateClickHandler}
                  isAnyPortcallInEditMode={isAnyPortcallInEditMode}
                  portCallId={portCallId}
                  baseUrlToMainApp={baseUrlToMainApp}
                />
              </Stack>
            </Stack>
          );
        }}
        renderBody={() => {
          return (
            <Box>
              {isLoading ? (
                <Box
                  sx={{
                    minHeight: "550px",
                    width: "1570px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Loader type={LARGE_STYLE} />
                </Box>
              ) : (
                <Box display="flex" flexDirection="row" height="100%">
                  <Box mb="8px" width="1070px" height="100%">
                    <ArrivalSectionMui
                      archivedPortcall={archivedPortcall}
                      editMode={editMode}
                      setEta={setEta}
                      setArrivalDraft={setArrivalDraft}
                      setStartOfSeaPassage={setStartOfSeaPassage}
                      setEndOfSeaPassage={setEndOfSeaPassage}
                      isLoading={isLoading}
                      currentPortcallErrors={errors}
                      portCallState={portCallState}
                      highlightedPortcallsFields={highlightedPortcallsFields}
                      commercialManagerOrgName={commercialManagerOrgName}
                      earliestEndOfLaycan={earliestEndOfLaycan}
                      setBunkersFuel={setBunkersFuel}
                      setFreshWater={setFreshWater}
                      isHighlightedField={isHighlightedField}
                      setFieldValue={setFieldValue}
                      portCallEtaResult={portCallState?.portCallEtaResult}
                      setEtaToShow={setEtaToShow}
                      errors={errors}
                    />

                    <BunkersLiftedMui
                      editMode={editMode}
                      bunkersTaken={bunkersTaken}
                      liftedBunkerProductsInPort={liftedBunkerProductsInPort}
                      setFieldValue={setFieldValue}
                      setLiftedBunkerProductField={setLiftedBunkerProductField}
                      setLiftedBunkerProductBargeField={
                        setLiftedBunkerProductBargeField
                      }
                      principalOrganizationId={principalOrganizationId}
                      commercialBunkerFactorPreferences={
                        commercialBunkerFactorPreferences
                      }
                      bunkerFactorsValue={bunkerFactorsValues}
                      setBunkerFactorsValue={setBunkerFactorValues}
                      initialBunkerFactorsValue={initialBunkerFactorsValues}
                      errors={errors}
                      selectedTab={selectedTab}
                      handleTabChange={handleTabChange}
                      tabLabels={tabLabels}
                    />
                    <DepartureSectionMui
                      archivedPortcall={archivedPortcall}
                      portCallState={portCallState}
                      isHighlightedField={isHighlightedField}
                      editMode={editMode}
                      setStartOfSeaPassage={setStartOfSeaPassage}
                      currentPortcallErrors={errors}
                      setDepartureDraft={setDepartureDraft}
                      isLoading={isLoading}
                      setBunkersFuel={setBunkersFuel}
                      setFreshWater={setFreshWater}
                      setEtd={setEtd}
                      setFieldValue={setFieldValue}
                      errors={errors}
                    />
                  </Box>
                  <Box>
                    <Divider
                      orientation="vertical"
                      sx={{
                        my: "20px",
                        height: "calc(100% - 40px) !important",
                        backgroundColor: "components.divider.color",
                      }}
                    />
                  </Box>
                  <Box width="500px" height="100%">
                    <Box ml="40px">
                      <LoadingDischargingSectionMui
                        cargoes={cargoes}
                        editMode={editMode}
                        setBillOfLadingStatus={setBillOfLadingStatus}
                        isLoading={isLoading}
                        liftedBunkerProductsInPort={liftedBunkerProductsInPort}
                        bunkersTaken={bunkersTaken}
                        bunkerFactorSettings={bunkerFactorSettings}
                        setFieldValue={setFieldValue}
                        setLiftedBunkerProductField={
                          setLiftedBunkerProductField
                        }
                        setLiftedBunkerProductBargeField={
                          setLiftedBunkerProductBargeField
                        }
                        principalOrganizationId={principalOrganizationId}
                        commercialBunkerFactorPreferences={
                          commercialBunkerFactorPreferences
                        }
                      />
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
          );
        }}
        renderFooter={() => {
          return (
            <>
              <RemarksSectionMui
                editMode={editMode}
                remarks={remarks}
                setRemarks={setRemarks}
                isLoading={isLoading}
                earlyDelayArrival={portCallState.earlyDelayArrival}
                setEarlyDelayArrivalReason={setEarlyDelayArrivalReason}
                setEarlyDelayArrivalComment={setEarlyDelayArrivalComment}
                currentPortcallErrors={errors}
              />
            </>
          );
        }}
      />
    </>
  );
};
export default PortcallCard;
